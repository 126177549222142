<template>
  <div class="bg">
    <div class="wrapper">
      <div style="display: flex; align-items: flex-start;">
        <div class="courseware-left">
          <!-- 课件标题信息 -->
          <div class="floor-a">
            <div class="title">{{ wareTitleData.title }}</div>
            <div class="floor-t-r-label">
              <div class="label-i"
                v-for="item of wareTitleData.wareLabels" :key="item"
              >{{ item }}</div>
            </div>
            <div style="color: #7B7B7B; font-size: 14px;">
              <span v-if="wareTitleData.joinWareNumber" style="margin-right: 12px;">
                 <icon-user style="margin-right: 8px;font-size: 18px;" />
                  <span >{{ wareTitleData.joinWareNumber }}</span>
              </span>
              <span>未经作者授权，禁止转载</span>
            </div>
          </div>
          <!-- 播放器 -->
          <div class="courseware-video-box">
            <video
              v-show="videoPlayData.videoUrl"
              id="videoDom"
              controls
              autobuffer
              controlslist="nodownload noplaybackrate"
              @timeupdate="videoTimeupdate"
              @play="videoPlay"
              @pause="videoPause"
              preload="auto"
            />
            <img v-show="!videoPlayData.videoUrl" :src="wareTitleData.cover" alt="" />
          </div>
          <div v-if="videoPlayData.videoUrl" class="video-bot-box">
            <div class="video-bot-box-row">
              <div class="ideo-bot-box-item">
                <div style="padding-left: 10px;">
                  <!-- <icon-star-fill class="cursor" style="font-size: 18px;color: #FF610D;" />
                  <icon-star-fill class="cursor" style="font-size: 18px;color: #666666;" />
                  123 -->
                </div>
              </div>
              <div class="video-bot-box-item">
                <div class="cursor" style="color: #666666;" @click="summonNotes">
                  <icon-bookmark />
                  记笔记
                </div>
                <div style="margin-left: 15px;">
                  <a-dropdown @select="dropdownSelect">
                  <!-- <a-dropdown> -->
                    <div class="cursor" style="padding: 10px;">
                      <icon-more-vertical />
                    </div>
                    <template #content>
                      <a-doption :value="1">视频投诉</a-doption>
                    </template>
                  </a-dropdown>
                </div>
              </div>
            </div>
            <div v-if="notesData.inputShow" class="textarea-box">
              <a-textarea style="flex: 1;" v-model="notesData.notes" placeholder="请输入笔记" show-word-limit :max-length="100" allow-clear/>
              <div class="textarea-box-btns">
                <div><a-button type="primary" size="small" :loading="notesData.loading" style="background-color: #4F17A8;" @click="notesSubmit">保存</a-button></div>
                <div><a-button size="small" style="margin-top: 10px;" @click="notesCancel">取消</a-button></div>
              </div>
            </div>
          </div>
          <!-- 课件简介 + 课件讲师 -->
          <a-card :style="{ marginTop: '20px', borderRadius: '4px' }" :bordered="false">
            <!-- 标签 -->
            <a-tabs
              :default-active-key="wareInfoData.tabActiveIndex"
              @change="handleTrigger"
            >
              <a-tab-pane
                v-for="(item, index) in wareInfoData.tab"
                :key="index" :title="item"
              />
            </a-tabs>
            <!-- 课件简介 -->
            <div v-show="wareInfoData.tabActiveIndex == 0"
              style="min-height: 300px;padding: 20px;"
            >{{ wareInfoData.intro }}</div>
            <!-- 课件讲师列表 -->
            <div v-show="wareInfoData.tabActiveIndex == 1"
              style="min-height: 300px;padding: 0 20px;"
            >
              <a-table
                :bordered="false"
                :pagination="false"
                :data="wareInfoData.teachers"
              >
                <template #columns>
                  <a-table-column title="姓名" data-index="name" :width="120"/>
                  <a-table-column title="简介" data-index="intro">
                      <template #cell="{ record }">
                        <a-tooltip :content="record.intro">
                          <div class="line-text-2" style="">{{record.intro}}</div>
                        </a-tooltip>
                      </template>
                  </a-table-column>
                  <a-table-column title="单位" data-index="company" :width="150"/>
                  <a-table-column title="职称" data-index="title" :width="150"/>
                </template>  
              </a-table>
            </div>
            <!-- 交流互动 -->
            <div v-show="wareInfoData.tabActiveIndex == 2"
              style="min-height: 300px;padding: 0 20px;"
            >
              <div class="comment-box">
                <div style="font-size: 16px;font-weight: 700;">评论</div>
                <div class="comment-input">
                  <img style="height: 32px;width: 32px;" src="../../assets/images/student.png" alt="">
                  <a-input
                    style="flex: 1;margin-left: 15px;margin-right: 15px;"
                    v-model="kjListLeaveData.kjListLeave"
                    size="large"
                    placeholder="请输入您的评论"
                    :allow-clear="true"
                  />
                  <a-button type="primary" size="large" style="background-color: #4F17A8;" :loading="kjListLeaveData.loading" @click="kjListLeaveSubmit(1)">发布</a-button>
                </div>
                <div class="comment-list">
                  <div class="comment-item"
                    v-for="(item, index) of kjListLeaveData.list" :key="item.id"
                  >
                    <div style="display: flex;">
                      <img style="height: 28px;width: 28px;" src="../../assets/images/student.png" alt="">
                      <div class="comment-item-right">
                        <div class="comment-item-right-name">{{ item.nickName }}</div>
                        <div class="comment-item-right-text">{{ item.leaveContent }}</div>
                        <div class="comment-item-right-bot">
                          <span>{{ item.createDate }}</span>
                          <span class="cursor hover-color" style="margin: 0 20px;" @click="replyInput(item.id, item.nickName, index)">回复</span>
                          <span class="cursor hover-color" @click="sommumKjListLeaveModel(item.id)">举报</span>
                        </div>
                      </div>
                    </div>
                    <div style="display: flex;padding: 10px 0px 0px 40px;"
                      v-for="itemSon of item.replyVos" :key="itemSon.id"
                    >
                      <img style="height: 24px;width: 24px;" src="../../assets/images/student.png" alt="">
                      <div class="comment-item-right" style="padding-left: 12px;padding-top: 4px;">
                        <div>
                          <span style="color:  #FF610D;padding-right: 6px;">{{ itemSon.nickName }}</span>
                          回复<span style="color: #40c5f1;padding-right: 6px;">@ {{ itemSon.replyName }}</span>
                          <span>: {{ itemSon.leaveContent }}</span>
                        </div>
                        <div class="comment-item-right-bot" style="padding-top: 10px;">
                          <span>{{ itemSon.createDate }}</span>
                          <span class="cursor hover-color" style="margin: 0 20px;" @click="replyInput(itemSon.id, itemSon.nickName, index)">回复</span>
                          <span class="cursor hover-color" @click="sommumKjListLeaveModel(itemSon.id)">举报</span>
                        </div>
                      </div>
                    </div>
                    <div v-if="kjListLeaveData.index == index" class="comment-input" style="margin-bottom: 0;margin-left: 25px;">
                      <a-input
                        style="flex: 1;margin-left: 15px;margin-right: 15px;"
                        v-model="kjListLeaveData.replyData"
                        size="large"
                        :placeholder="'回复@' + kjListLeaveData.replyName"
                        :allow-clear="true"
                      />
                      <a-button type="primary" :loading="kjListLeaveData.replyLoading" size="large" style="background-color: #4F17A8;" @click="kjListLeaveSubmit(2)">发布</a-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a-card>
        </div>
        <div class="courseware-right">
          <!-- 节笔记列表 -->
          <Accordion
            style="margin-bottom: 15px;"
            :flag="notesData.flag"
          >
            <template #title>
              <div class="accordion-title">课堂笔记({{ notesData.list.length }})</div>
            </template>
            <template #content>
              <div class="accordion-list">
                <div class="accordion-list-item" style="color: #666666;">
                  <div>时间</div>
                  <div style="flex: 1;padding-left: 35px;">笔记内容</div>
                  <div style="width: 70px;">发送时间</div>
                </div>
                <div class="accordion-list-item"
                  v-for="(item, index) of notesData.list" :key="index"
                  @mouseover="notesData.index = index"
                  @mouseleave="notesData.index = -1"
                  :class="{ 'accordion-list-item-back':notesData.index == index }"
                >
                  <div style="color: #666666;">{{ item.recordNew }}</div>
                  <a-tooltip :content="item.content">
                    <div class="accordion-list-item-info">{{ item.content }}</div>
                  </a-tooltip>
                  <div style="width: 70px;">
                    <div v-if="notesData.index != index" style="color: #666666;">{{ item.createDateNew[0] }}</div>
                    <div v-else class="accordion-list-item-btns">
                      <span class="cursor" style="color: #4F17A8;" @click="summonNotesEditModel(item)">修改</span>
                      <span class="cursor" style="color: rgb(var(--danger-6));" @click="notesDel(item.id)">删除</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Accordion>
          <!-- 课件视频列表 -->
          <div class="courseware">
            <div class="title">
              <div class="text">课件目录</div>
            </div>
            <div class="courseware-list">
              <!-- 节列表 -->
              <div v-if ="wareChaptersData.chapterType == 1">
                <div
                  v-for="(items, index) in wareChaptersData.chapters" :key="index"
                  class="titleBox cursor"
                  :class="{'titleBox-on': videoPlayData.chapterIndex == index }"
                  @click="chapterClick(items, index)"
                >
                  <div class="titleBox-tltle" style="flex:1;">
                    <div class="line-text-1">{{items.name}}</div>
                    <div style="padding-left: 14px;padding-top: 5px;">视频时长 {{items.durationNew}}</div>
                  </div>
                  <div class="collapse-item-time itemBox" style="color: #FF610D;" >已观看{{items.progress + '%'}}</div>
                  <div class="collapse-item-time itemBox" style="color: #52C41A;" v-if="items.status == 2">未观看</div>
                </div>
              </div>
              <!-- 章列表 -->
              <a-collapse
                v-else
                expand-icon-position="right"
                :default-active-key="[0]"
                :bordered="false"
              >
                <template #expand-icon="{ active }">
                  <icon-up v-if="active"/>
                  <icon-down v-else/>
                </template>
                <a-collapse-item :header="item.name" v-for="(item, index) in wareChaptersData.chapters" :key="index">
                  <div
                    v-for="(items, indexs) in item.children" :key="indexs"
                    class="collapse-item cursor"
                    :style="{
                      'color': videoPlayData.chapterIndex == indexs ? '#4F17A8': '',
                      'background': videoPlayData.chapterIndex == indexs ? '#f5f5f5': '',
                    }"
                    @click="chapterClick(items, indexs, index)">
                    <div class="collapse-item-title">
                      <div class="line-text-1">{{items.name}}</div>
                      <div style="padding-left: 14px;">视频时长 {{items.durationNew}}</div>
                    </div>
                    <div class="collapse-item-time" style="color: #FF610D;" v-if="items.progress > 0">已观看{{items.progress + '%'}}</div>
                    <div class="collapse-item-time" style="color: #52C41A;" v-if="items.progress == 0">未观看</div>
                  </div>
                </a-collapse-item>
              </a-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 视频投诉弹窗 -->
    <a-modal v-model:visible="complaintModelData.show"
      :title="'视频投诉'"
      :mask-closable="false"
      :footer='false'
      width="700px"
      @close="complaintModelCancel"
    >
      <a-form
        ref="complaintModelFormDom"
        :model="complaintModelData.fromData"
        @submit="complaintModelSubmit"
        :label-col-props="{ span: 4 }"
        label-align="right"
      >
        <a-form-item field="checked" :label="'投诉类型'"
        :rules="[{ required: true, message: '投诉类型不能为空'}]"
        :validate-trigger="['input']"
        >
          <!-- <a-radio-group direction="vertical"> -->
          <a-radio-group v-model="complaintModelData.fromData.checked" style="width: 100%;">
            <a-radio
              v-for="(value, key) in complaintModelData.checkeddata" :key="key"
              :value="key" :style="{ width: key == 11 ? '85%' : '45%' }"
            >{{ value }}</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item field="intro" :label="'投诉描述'" 
          :rules="[{ required: true, message: '投诉描述不能为空'}]"
          :validate-trigger="['change','input']"
        >
          <a-textarea
            placeholder="请输入投诉描述"
            v-model="complaintModelData.fromData.intro"
            allow-clear
            show-word-limit
            :max-length="100"
            :auto-size="{minRows:5}"
          />
        </a-form-item>
        <a-space style="justify-content: right;">
          <a-button @click="complaintModelCancel">取消</a-button>
          <a-button html-type="submit" type="primary" style="background-color: #4F17A8;" :loading="complaintModelData.loading">提交</a-button>
        </a-space>
      </a-form>
    </a-modal>
    <!-- 留言投诉弹窗 -->
    <a-modal v-model:visible="kjListLeaveData.show"
      :title="'留言举报'"
      :mask-closable="false"
      :footer='false'
      width="700px"
      @close="kjListLeaveModelCancel"
    >
      <a-form
        ref="kjListLeaveModelFormDom"
        :model="kjListLeaveData.fromData"
        @submit="kjListLeaveModelSubmit"
        :label-col-props="{ span: 4 }"
        label-align="right"
      >
        <a-form-item field="checked" :label="'举报类型'"
        :rules="[{ required: true, message: '举报类型不能为空'}]"
        :validate-trigger="['input']"
        >
          <!-- <a-radio-group direction="vertical"> -->
          <a-radio-group v-model="kjListLeaveData.fromData.checked" style="width: 100%;">
            <a-radio
              v-for="(value, key) in kjListLeaveData.checkeddata" :key="key"
              :value="key" :style="{ width: key == 11 ? '85%' : '45%' }"
            >{{ value }}</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item field="intro" :label="'举报描述'" 
          :rules="[{ required: true, message: '举报描述不能为空'}]"
          :validate-trigger="['change','input']"
        >
          <a-textarea
            placeholder="请输入举报描述"
            v-model="kjListLeaveData.fromData.intro"
            allow-clear
            show-word-limit
            :max-length="100"
            :auto-size="{minRows:5}"
          />
        </a-form-item>
        <a-space style="justify-content: right;">
          <a-button @click="kjListLeaveModelCancel">取消</a-button>
          <a-button html-type="submit" type="primary" style="background-color: #4F17A8;" :loading="kjListLeaveData.modelLoading">提交</a-button>
        </a-space>
      </a-form>
    </a-modal>
    <!-- 修改笔记弹窗 -->
    <a-modal v-model:visible="notesData.editData.show"
      :title="'修改笔记'"
      :mask-closable="false"
      :footer='false'
      width="500px"
      @close="notesEditModelCancel"
    >
      <a-form
        :model="notesData.editData.show"
        @submit="notesEditModelSubmit"
        :label-col-props="{ span: 4 }"
        label-align="right"
      >
        <a-form-item field="content" :label="'笔记'">
          <a-textarea
            placeholder="请输入投诉描述"
            v-model="notesData.editData.content"
            allow-clear
            show-word-limit
            :max-length="100"
            :auto-size="{minRows:3}"
          />
        </a-form-item>
        <a-space style="justify-content: right;">
          <a-button @click="complaintModelSubmit">取消</a-button>
          <a-button html-type="submit" type="primary" :loading="notesData.editData.loading">提交</a-button>
        </a-space>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup>
  import { reactive, ref, onUnmounted, nextTick } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import {
    payInfo,
    lock,
    addLock,
    releaseLock,
    playAuth,
    recordLog,
    playInfo,
    GetNotesList,
    AddNotes,
    EditNotes,
    DelNotes,
    GetkjListLeave,
    AddkjListLeave,
    AddkjListLeaveReport,
    AddVideoReport,
  } from '@/api/ware';
  import { Message, Modal } from '@arco-design/web-vue';
  import { formatSeconds, randomID } from '@/utils/util';
  import Accordion from '@/components/title/accordion.vue'

  const router = useRouter()
  const route = useRoute()
  const randomId = randomID()
  const courseId = route.query.courseId; // --- 课程ID
  const wareId = route.query.id; // ----------- 课件ID
  const classId = route.query.classId; // ----- 班级ID
  /**
   * 课件数据相关
   */
  // 课件内部章节列表
  const wareChaptersData = reactive({
    chapters: [], // ---------- 章节列表
    chapterType: 0, // -------- 课件列表首层是章还是节  1章  2节
  });
  /**
   * 视频播放相关方法
   */
  // 初始视频播放数据
  const videoPlayDataInit = () => {
    return {
      chapterFIndex: -1, // ----- 选中章的索引
      chapterIndex: -1, // ------ 选中节的索引
      payId: '', // ------------- 视频播放记录ID
      chapterId: '', // --------- 选中节的ID
      videoId: '', // ----------- 选中节里面的视频ID
      videoUrl: '', // ---------- 视频播放地址
      videoDuration: 0, // ------ 视频总时长--秒
      videoValidDuration: 0, // - 视频的有效拖动最大进度
      videoPlan: 0, // ---------- 选中视频的播放进度--秒
      videoStatus: 'init', // --- 视频播放状态 play播放 pause暂停  'init'初始状态
      videoPlayTimer: null, // -- 视频播放计时器
      videoUpTime: 0, // -------- 视频播放时长（定时上传进度需要）
      videoStartPlan: 0, // ----- 视频开始播放时的进度（定时上传进度需要）
      daozhivideoPlan: -1, // --- 视频来回捯饬了时的进度
    }
  }
  // 视频播放数据
  const videoPlayData = ref(videoPlayDataInit());
  // 视频播放是拖动还是播放的标识数
  const VIDEO_PLAN_FLAG = 1;
  // 视频播放没10秒上传一次进度
  const VIDEO_UPLOAD_PLAN_TIME = 10;
  /**
   * 点击节--切换视频播放
   * @param  {*} chapterData obj 节相关数据
   */
  const chapterClick = async (chapterData, jIndex, zIndex = -1) => {
    // 如果上一个视频正在播放中，先上传进度，然后执行下边
    if (videoPlayData.value.videoStatus === 'play') {
      clearVideoPlayTimer();
      try {
        const videoLoadPlanRes = await videoLoadPlan(videoPlayData.value.videoPlan);
        if (!videoLoadPlanRes) { return }
        await releaseLock({ videoId: videoPlayData.value.videoId });
      } catch (err) {
        Message.error(err);
        return;
      }
    }
    if (videoPlayData.value.videoStatus === 'pause') {
      clearVideoPlayTimer();
      try {
        await releaseLock({ videoId: videoPlayData.value.videoId });
      } catch (err) {
        Message.error(err);
        return;
      }
    }
    // 初始视频播放数据
    videoPlayData.value = videoPlayDataInit();
    // 章索引
    videoPlayData.value.chapterFIndex = zIndex;
    // 节索引
    videoPlayData.value.chapterIndex = jIndex;
    // 节ID
    videoPlayData.value.chapterId = chapterData.id;
    // 节中视频ID
    videoPlayData.value.videoId = chapterData.resourcePath;
    // 视频播放记录ID
    videoPlayData.value.payId = chapterData.payId;
    // 节中视频总时长
    videoPlayData.value.videoDuration = chapterData.duration;
    // 节中视频最大拖动进度
    videoPlayData.value.videoValidDuration = chapterData.validDuration;
    try {
      // 检验视频锁是否存在, 为了检验是否多开，同一账号只能打开一次
      const lockRes = await lock({
        videoId: chapterData.resourcePath,
        uid: randomId,
      });
      if (lockRes.code !== 20000) {
        if (lockRes.code === 5856) {
          videoStopOverturning();
          return;
        }
      }
      // 获取视频播放凭证
      const playAuthRes = await playAuth({ videoId: chapterData.resourcePath });
      if (playAuthRes.code !== 200) { return }
      // 获取播放进度
      const recordLogRes = await recordLog({
        chapterId: chapterData.id, // - 节ID
        courseId: classId, // --------- 班级ID
        plan: -1, // ------------------ 传-1就是获取上次播放进度，传正常数值就是上传视频进度
        wareId: wareId, // ------------ 课件ID
      });
      if (recordLogRes.code !== 20000) { return }
      // 获取最新播放进度
      if (recordLogRes.result) {
        videoPlayData.value.videoPlan = recordLogRes.result.plan;
      } else {
        videoPlayData.value.videoPlan = 0;
      }
      // 获取视频播放地址
      const playInfoRes = await playInfo({ videoId: chapterData.resourcePath });
      if (playInfoRes.code !== 200) { return }
      const playUrl = playInfoRes.result.playInfoList[0].playURL;
      // 设置视频播放地址和播放进度
      playBySeconds(videoPlayData.value.videoPlan, playUrl);
      // 获取节的笔记
      getNotesAllList();
    } catch (err) {
      Message.error(err);
    }
  };
  /**
   * 设置视频播放地址和播放进度
   * @param {*} videoPlan number 视频播放进度
   * @param {*} playUrl string 视频播放地址
   */
  const playBySeconds = (videoPlan, playUrl) => {
    const videoDom = document.getElementById('videoDom');
    if (videoDom) {
      if (playUrl) {
        videoPlayData.value.videoUrl = playUrl;
        // videoDom.src = playUrl + '?time=' + new Date().getTime();
        videoDom.src = playUrl;
      }
      videoDom.currentTime = videoPlan;
      // 更新最新的播放进度
      videoPlayData.value.videoPlan = videoPlan;
    }
  };
  /**
   * 上传视频播放进度
   * @param {*} videoPlan number 最新视频进度
   * 上传规则：正常播放每VIDEO_UPLOAD_PLAN_TIME秒上传一次，暂停或拖动上传然后重新计时
   */
  const videoLoadPlan = async (videoPlan) => {
    if (videoPlayData.value.videoStartPlan === null) {
      return;
    }
    try {
      const recordLogRes = await recordLog({
        chapterId: videoPlayData.value.chapterId, // - 节ID
        courseId: classId, // --------- 班级ID
        startPlan: videoPlayData.value.videoStartPlan, // 开始时的进度
        plan: videoPlan, // -- 传-1就是获取上次播放进度，传正常数值就是上传视频进度
        wareId: wareId, // ------------ 课件ID
        payId: videoPlayData.value.payId,
        uid: randomId,
      });
      if (recordLogRes.code === 20000) {
        // 更新视频开始播放时的进度
        videoPlayData.value.videoStartPlan = videoPlayData.value.videoPlan;
        // 更新视频来回捯饬了时的进度
        videoPlayData.value.daozhivideoPlan = -1;
        // 更新列表进度比例和最大拖拽进度和进度
        if (videoPlayData.value.chapterFIndex > -1) {
          // 有章
          wareChaptersData.chapters[videoPlayData.value.chapterFIndex][videoPlayData.value.chapterIndex].plan =
          recordLogRes.result.plan;
          wareChaptersData.chapters[videoPlayData.value.chapterFIndex][videoPlayData.value.chapterIndex].validDuration =
          recordLogRes.result.validDuration;
          wareChaptersData.chapters[videoPlayData.value.chapterFIndex][videoPlayData.value.chapterIndex].progress =
          recordLogRes.result.progress;
        } else {
          // 没有章
          wareChaptersData.chapters[videoPlayData.value.chapterIndex].plan =
          recordLogRes.result.plan;
          wareChaptersData.chapters[videoPlayData.value.chapterIndex].validDuration =
          recordLogRes.result.validDuration;
          wareChaptersData.chapters[videoPlayData.value.chapterIndex].progress =
          recordLogRes.result.progress;
        }
        return true;
      } else {
        // 多开关闭视频
        if (recordLogRes.code === 5856) {
          videoStopOverturning();
        }
        return false;
      }
    } catch (err) {
      Message.error(err);
      return false;
    }
  }
  /**
   * 多开停止视频
   */
  const videoStopOverturning = () => {
    const videoDom = document.getElementById('videoDom');
    videoDom.currentTime = 0;
    videoDom.src = '';
    videoPlayData.value = videoPlayDataInit();
    Modal.warning({
      title: '提示',
      content: '请先停止其他视频，再进行学习'
    });
  }
  /**
   * 视频播放进度条变化
   * @param {*} e video元素timeupdate方法默认参数对象
   */
  const videoTimeupdate = (e) => {
    if (videoPlayData.value.videoUpTime >= VIDEO_UPLOAD_PLAN_TIME) {
      // 上传视频播放进度
      videoPlayData.value.videoUpTime = 0;
      videoLoadPlan(videoPlayData.value.videoPlan);
    }
    // 当前视频进度位置--秒
    const vPlan = e.target.currentTime;
    // 播放状态
    if (videoPlayData.value.videoStatus === 'play') {
      // 当前视频进度大于视频的有效拖动最大进度
      if (vPlan > videoPlayData.value.videoValidDuration) {
        if(vPlan - videoPlayData.value.videoValidDuration < VIDEO_PLAN_FLAG) {
          // 正常播放
          // 更新最新的视频的有效拖动最大进度
          videoPlayData.value.videoValidDuration = vPlan;
          // 更新最新的播放进度
          videoPlayData.value.videoPlan = vPlan;
        } else {
          // 拖动超出视频的有效拖动最大进度
          playBySeconds(videoPlayData.value.videoValidDuration);
        }
      } else {
        if (
          vPlan < videoPlayData.value.videoPlan ||
          vPlan - videoPlayData.value.videoPlan > VIDEO_PLAN_FLAG
        ) {
          // 来回捯饬了
          // console.log('来回捯饬了');
          videoPlayData.value.daozhivideoPlan = videoPlayData.value.videoPlan;
        }
        // 更新最新的播放进度
        videoPlayData.value.videoPlan = vPlan;
      }
    } else {
      // 暂停状态
      // 判断当前进度是否大于最大拖动进度
      // 如果大于那么当前进度就等于最大拖动进度
      if (vPlan > videoPlayData.value.videoValidDuration) {
        playBySeconds(videoPlayData.value.videoValidDuration);
      }
    }
  };
  // 视频开始播放
  const videoPlay = async () => {
    // console.log('开始播放');
    // 记录播放状态
    if (videoPlayData.value.videoStatus === 'init') {
      videoPlayData.value.videoStatus = 'play';
      try {
        // 检验视频锁是否存在
        const lockRes = await lock({
          videoId: videoPlayData.value.videoId,
          uid: randomId,
        });
        if(lockRes.code !== 20000) {
          if (lockRes.code === 5856) {
            videoStopOverturning();
            return;
          }
          playBySeconds(videoPlayData.value.videoPlan);
          return;
        }
        // 添加视频锁
        const addLockRes = await addLock({
          videoId: videoPlayData.value.videoId,
          uid: randomId,
        });
        if(addLockRes.code !== 20000) {
          playBySeconds(videoPlayData.value.videoPlan);
          return;
        }
        // 记录视频开始播放时的进度
        videoPlayData.value.videoStartPlan = videoPlayData.value.videoPlan;
        // 设置视频播放计时器 每秒一加
        setVideoPlayTimer();
      } catch (err) {
        playBySeconds(videoPlayData.value.videoPlan);
        Message.error(err);
      }
    } else {
      // 设置视频播放计时器 每秒一加
      setVideoPlayTimer();
    }
    videoPlayData.value.videoStatus = 'play';
  };
  // 设置计时器
  const setVideoPlayTimer = () => {
    if (videoPlayData.value.videoPlayTimer === null) {
      videoPlayData.value.videoPlayTimer = setInterval(() => {
        if (videoPlayData.value.videoStatus === 'pause') {
          clearInterval(videoPlayData.value.videoPlayTimer);
          videoPlayData.value.videoPlayTimer = null;
        } else {
          videoPlayData.value.videoUpTime++;
        }
      }, 1000);
    }
  }
  // 清空计时器
  const clearVideoPlayTimer = () => {
    clearInterval(videoPlayData.value.videoPlayTimer);
    videoPlayData.value.videoPlayTimer = null;
    videoPlayData.value.videoUpTime = 0;
  }
  // 视频暂停播放
  const videoPause = async () => {
    // 更新视频播放状态
    videoPlayData.value.videoStatus = 'pause';
    // 清理视频播放计时器
    clearVideoPlayTimer();
    // 上传进度
    await videoLoadPlan(
      videoPlayData.value.daozhivideoPlan === -1 ?
      videoPlayData.value.videoPlan :
      videoPlayData.value.daozhivideoPlan
    );
  }

  /**
   * 笔记相关
   */
  const notesData = reactive({
    inputShow: false,
    time: '',
    notes: '',
    loading: false,
    list: [],
    index: -1,
    flag: true,
    editData: {
      show: false,
      loading: false,
      content: '',
      id: ''
    }
  });
  // 获取笔记列表
  const getNotesAllList = async () => {
    const data = await GetNotesList({
      chapterId: videoPlayData.value.chapterId,
      size: 99999,
    });
    if (data.code === 20000) {
      const list = data.result.records;
      if (list.length > 0) {
        list.forEach(item => {
          item.recordNew = formatSeconds(item.record);
          item.createDateNew = item.createDate.split(' ');
        });
      }
      notesData.list = list;
      nextTick(() => {
        notesData.flag = !notesData.flag;
      });
    }
  }
  // 召唤笔记input
  const summonNotes = () => {
    notesData.inputShow = true;
    notesData.time = videoPlayData.value.videoPlan;
  }
  // 提交笔记
  const notesSubmit = async () => {
    notesData.loading = true;
    console.log(notesData.notes);
    if (!notesData.notes) {
      Message.warning('请输入笔记');
      return;
    }
    const params = {
      chapterId: videoPlayData.value.chapterId,
      content: notesData.notes,
      courseId: courseId,
      wareId: wareId,
      record: notesData.time,
    }
    const data = await AddNotes(params); 
    if (data.code === 20000) {
      Message.success('笔记提交成功');
      getNotesAllList();
      notesCancel();
    }
  }
  // 取消笔记
  const notesCancel = () => {
    notesData.loading = false;
    notesData.notes = '';
    notesData.time = 0;
    notesData.inputShow = false;
  }
  // 召唤修改笔记弹窗
  const summonNotesEditModel = (item) => {
    notesData.editData.show = true;
    notesData.editData.id = item.id;
    notesData.editData.content = item.content;
  }
  // 提交修改笔记
  const notesEditModelSubmit = async () => {
    if (!notesData.editData.content) {
      Message.warning('笔记不能为空');
      return;
    }
    notesData.editData.loading = true;
    const data = await EditNotes({
      content: notesData.editData.content,
      id: notesData.editData.id,
    });
    notesData.editData.loading = false;
    if (data.code === 20000) {
      Message.success('笔记修改成功');
      getNotesAllList();
      notesEditModelCancel();
    }
  }
  // 关闭修改笔记弹窗
  const notesEditModelCancel = () => {
    notesData.editData.show = false;
    notesData.editData.loading = false;
    notesData.editData.id = '';
    notesData.editData.content = '';
  }
  // 删除笔记
  const notesDel = (id) => {
    return new Promise((resolve, reject) => {
      Modal.confirm({
        title: '确认删除',
        content: '确认删除此笔记吗？',
        onOk: () => {
          DelNotes({ id }).then((res) => {
            if (res.code === 20000) {
              Message.success('删除成功');
              getNotesAllList();
              resolve(true);
            }
          });
        },
        onCancel: () => reject('cancel'),
      });
    });
  }

  /**
   * 投诉相关
   */
  const complaintModelFormDom = ref();
  const complaintModelData = reactive({
    show: false,
    loading: false,
    checkeddata: {
      1: '音画不同步',
      2: '播放卡顿',
      3: '视频损坏，无法播放',
      4: '黑屏',
      5: '内容不符',
      6: '涉及违法信息',
      7: '涉及敏感话题',
      8: '涉及版权侵犯',
      9: '涉及民族宗教类违规行为',
      10: '涉及未成年人不当行为',
      11: '涉及信息诈骗，垃圾广告，造谣传谣',
    },
    fromData: {
      checked: '',
      intro: '',
    },
  });
  // 召唤投诉弹窗
  const dropdownSelect = (val) => {
    if (val === 1) {
      // 投诉
      complaintModelData.show = true;
    }
  }
  // 关闭投诉弹窗
  const complaintModelCancel = () => {
    complaintModelData.loading = false;
    complaintModelData.show = false;
    complaintModelData.fromData.checked = '';
    complaintModelData.fromData.intro = '';
  }
  // 提交投诉弹窗
  const complaintModelSubmit = async () => {
    const errors = await complaintModelFormDom.value?.validate();
    if (!errors) {
      complaintModelData.loading = true;
      const data = await AddVideoReport({
        chapterId: videoPlayData.value.chapterId,
        courseId: courseId,
        wareId: wareId,
        content: complaintModelData.fromData.intro,
        type: complaintModelData.fromData.checked,
      });
      if (data.code === 20000) {
        Message.success('投诉成功');
        complaintModelCancel();
      }
    }
  }

  /**
   * 评论相关
   */
  const kjListLeaveModelFormDom = ref();
  const kjListLeaveData = reactive({
    list: [],
    index: -1,
    loading: false,
    replyLoading: false,
    replyId: '',
    replyName: '',
    kjListLeave: '', // 留言输入框值
    replyData: '', // 回复输入框值
    // 投诉
    id: '',
    show: false,
    modelLoading: false,
    checkeddata: {
      10: '辱骂、攻击、骚扰等',
      20: '色情低俗',
      30: '内容令人反感',
      40: '垃圾广告',
      50: '刷屏、无意义内容',
      60: '涉及未成年人不良信息',
      70: '涉证造谣等',
      80: '违法信息',
      90: '其他',
    },
    fromData: {
      checked: '',
      intro: '',
    },
  });
  // 获取留言列表
  const getAllkjListLeave = async () => {
    const data = await GetkjListLeave({ id: wareId, size: 99999 });
    if (data.code === 20000) {
      kjListLeaveData.list = data.result.records;
    }
  }
  getAllkjListLeave();
  // 点击回复
  const replyInput = (id, name, index) => {
    kjListLeaveData.index = index;
    kjListLeaveData.replyName = name;
    kjListLeaveData.replyId = id;
  }
  // 增加留言和回复
  const kjListLeaveSubmit = async (type) => {
    const params = {
      courseId,
      wareId,
    }
    if (type === 1) {
      // 提交留言
      if (!kjListLeaveData.kjListLeave) {
        return Message.warning('留言不能为空');
      }
      kjListLeaveData.loading = true;
      params.leaveContent = kjListLeaveData.kjListLeave;
    } else {
      // 提交回复
      if (!kjListLeaveData.replyData) {
        return Message.warning('回复不能为空');
      }
      kjListLeaveData.replyLoading = true;
      params.replyUserId = kjListLeaveData.replyId;
      params.leaveContent = kjListLeaveData.replyData;
    }
    const data = await AddkjListLeave(params);
    kjListLeaveData.loading = false;
    kjListLeaveData.replyLoading = false;
    if (data.code === 20000) {
      Message.success('留言发布成功');
      getAllkjListLeave();
      kjListLeaveData.index = -1;
      if (type === 1) {
        kjListLeaveData.kjListLeave = '';
      } else {
        kjListLeaveData.replyData = '';
      }
    }
  }
  // 召唤留言投诉弹窗
  const sommumKjListLeaveModel = (id) => {
    kjListLeaveData.id = id;
    kjListLeaveData.show = true;
  }
  // 留言举报提交
  const kjListLeaveModelSubmit = async () => {
    const errors = await kjListLeaveModelFormDom.value?.validate();
    if (!errors) {
      kjListLeaveData.modelLoading = true;
      const data = await AddkjListLeaveReport({
        leaveReportId: kjListLeaveData.id,
        reportType: kjListLeaveData.fromData.checked,
        reportContent: kjListLeaveData.fromData.intro,
      });
      if (data.code === 20000) {
        Message.success('举报成功');
        kjListLeaveModelCancel();
      }
    }
  }
  // 关闭留言投诉弹窗
  const kjListLeaveModelCancel = () => {
    kjListLeaveData.id = '';
    kjListLeaveData.fromData.checked = '';
    kjListLeaveData.fromData.intro = '';
    kjListLeaveData.show = false;
    kjListLeaveData.modelLoading = false;
  }

  /**
   * 初始化课件数据
   */
  // 课件简介、讲师数据
  const wareInfoData = reactive({
    tab: ['课件介绍', '课件讲师', '交流互动'],
    tabActiveIndex: 0, // - tab索引
    intro: '', // --------- 课件简介
    teachers: [], // ------ 课件讲师列表
  });
  /**
   * 标签切换
   * @param {*} index number 标签索引
   */
  const handleTrigger = (index) => {
    wareInfoData.tabActiveIndex = index;
  }
  // 课件标题信息数据
  const wareTitleData = reactive({
    title: '', // --------- 课件标题
    wareLabels: [], // ---- 课件标签
    joinWareNumber: 0, // - 课件学习人数
    cover: '', // --------- 课件缩略图
  });
  // 课件详情方法
  const getPayInfo = async () => {
    try {
      const wareRes = await payInfo({
        courseId: courseId,
        id: wareId,
        classId: classId,
      });
      if (wareRes.code === 20000) {
        const data = wareRes.result;
        // 课件标题
        wareTitleData.title = data.name;
        // 转换标签--字符串转数组
        wareTitleData.wareLabels = data.wareLabels.split('、');
        // 课件学习人数
        wareTitleData.joinWareNumber = data.joinWareNumber;
        // 课件缩略图
        wareTitleData.cover = '/_fid/' + data.cover;
        // 课件简介
        wareInfoData.intro = data.intro;
        // 课件讲师列表
        wareInfoData.teachers = data.teachers || [];
        // 课件章节列表--转换视频时长
        if (!data.chapters || data.chapters.length < 1) {
          Message.error('课件数据有误！');
          return;
        }
        data.chapters.forEach((item, index) => {
          if (index === 0) {
            // 课件列表首层章节标识
            wareChaptersData.chapterType = item.chapterState;
          }
          if (item.chapterState === 1) {
            // 课件 -> 节
            item.durationNew = formatSeconds(item.duration);
          } else {
            // 课件 -> 章 -> 节
            if (item.children && item.children.length > 0) {
              item.children.forEach(childrenItem => {
                childrenItem.durationNew = formatSeconds(childrenItem.duration);
              });
            }
          }
        });
        wareChaptersData.chapters = data.chapters;
      }
    } catch (err) {
      Message.error(err);
    }
  };
  // 获取课件详情
  getPayInfo();
  //关闭浏览器调用
  const beforeunloadHandler = async () => {
    if (videoPlayData.value.videoStatus === 'play') {
      clearVideoPlayTimer();
      try {
        const videoLoadPlanRes = await videoLoadPlan(videoPlayData.value.videoPlan);
        if (!videoLoadPlanRes) { return }
        await releaseLock({ videoId: videoPlayData.value.videoId });
      } catch (err) {
        Message.error(err);
        return;
      }
    }
  }
  window.addEventListener('beforeunload', () => beforeunloadHandler());
  // 页面离开
  onUnmounted(() => {
    beforeunloadHandler();
    window.removeEventListener('beforeunload', beforeunloadHandler());
  })
</script>

<style lang="less" scoped>
/deep/.arco-tabs-tab-active {
    color: #4F17A8 !important;
}
/deep/.arco-tabs-nav-ink {
  background-color: #4F17A8;
}
.hover:hover {
  color: #4F17A8;
  .hover {
    color: #4F17A8;
  }
}
.accordion-title {
  line-height: 26px;
  font-weight: 700;
  font-size: 16px;
}
.accordion-list {
  padding: 15px 10px 15px 0;
  .accordion-list-item {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 20px;
    padding: 10px 0;
    .accordion-list-item-info {
      width: 220px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .accordion-list-item-btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .accordion-list-item-back {
    background-color: #f1f1f1;
  }
}
.video-bot-box {
  margin-top: 10px;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 5px;
  .video-bot-box-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .video-bot-box-item {
      display: flex;
      align-items: center;
    }
  }
  .textarea-box {
    display: flex;
    padding: 10px;
    .textarea-box-btns {
      padding-left: 10px;
    }
  }
}
.comment-box {
  padding: 10px 0;
  .comment-input {
    margin: 15px 0px 10px 0px;
    display: flex;
    align-items: center;
  }
  .comment-list {
    .comment-item {
      padding: 15px 0;
      border-bottom: 1px solid #E9E9E9;
      .comment-item-right {
        flex: 1;
        padding-left: 18px;
        padding-top: 8px;
        font-size: 14px;
        color: #7B7B7B;
        .comment-item-right-name {
          color:  #FF610D;
        }
        .comment-item-right-text {
          padding: 10px 0;
        }
        .comment-item-right-bot {
          font-size: 12px;
          color: #B7B7B7;
        }
      }
    }
  }
}
.hover-color:hover {
  color: #4F17A8;
}

.bg {
  background: #F7F6FA;
  padding: 20px 0;
}
.courseware-left {
  width: 780px;
  margin-right: 20px;
  .floor-a {
    padding: 16px;
    background: #fff;
    border-radius: 5px;
    .title {
      font-weight: 700;
      font-size: 22px;
      color: #323233;
    }
    .floor-t-r-label {
      margin: 10px 0;
      display: flex;
      .label-i + .label-i {
        margin-left: 10px;
      }
      .label-i {
        height: 22px;
        background: #FFF7E6;
        border-radius: 2px;
        border: 1px solid #FFD591;
        padding: 0 10px;
        color: #D46B08;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .courseware-video-box {
    margin-top: 20px;
    background: #000;
    height: 440px;
    video, img {
      width: 100%;
      height: 100%;
    }
  }
  .floor-c {
    margin-top: 10px;
    height: 50px;
    background: #FFFFFF;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
  }
  .floor-d {
    margin-top: 20px;
    background: #FFFFFF;
    border-radius: 4px;
    .reply-box {
      display: flex;
      margin-top: 30px;
      padding-left: 10px;
      .reply-box-avatar {
        margin-right: 12px;
        img {
          width: 44px;
          height: 44px;
          border-radius: 50%;
        }
      }
      .reply-box-warp {
        flex: 1;
      }
      .reply-box-send {
        margin-left: 20px;
        width: 82px;
        height: 54px;
        background: #4F17A8;
        border-radius: 4px;
        font-size: 18px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .root-reply-container {
      padding: 20px 0;
      display: flex;
      align-items: flex-start;
      .root-reply-avatar {
        margin-right: 12px;
        img {
          width: 44px;
          height: 44px;
          border-radius: 50%;
        }
      }
      .content-warp {
        flex: 1;
        border-bottom: 1px solid #E9E9E9;
      }

    }
    .tab {
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      border-bottom: 1px solid #e9e9e9;
      .tab-pane {
        display: flex;
        align-items: center;
        margin: 0 24px;
        height: 60px;
        color: #474747;
        cursor: pointer;
        border-bottom: 3px solid transparent;
      }
      .active {
        color: #4F17A8;
        font-weight: 600;
        border-bottom: 3px solid #4F17A8;
      }
    }
  }
  .floor-b-a {
    padding: 20px 30px;
  }
  .floor-b-c {
    padding: 20px;
  }
  .floor-b-d, .floor-b-e, .floor-b-f {
    padding: 20px;
  }
  .floor-b-e {
    .file-item:hover {
      background-color: #f0f3fa;
    }
    .file-item {
      cursor: pointer;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      height: 50px;
      background: #FAFAFA;
      border-radius: 4px;
      border: 1px solid #E9E9E9;
      padding: 0 15px;
      color: #474747;
      font-size: 14px;
      .file-name {
        flex: 1;
      }
      .file-btn {
        margin-left: 40px;
        width: 100px;
        height: 26px;
        background: #4F17A8;
        border-radius: 4px;
        font-size: 14px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.courseware-right {
  flex: 1;
  .note {
    background: #fff;
    .note-box::-webkit-scrollbar {
      width: 10px;
    }
    .note-box::-webkit-scrollbar-thumb {
      background: #ccc content-box;
      border: 2px solid transparent;
      border-radius: 4px;
    }
    .note-box {
      height: 216px;
      overflow-y: auto;
    }
    .note-item {
      font-size: 12px;
      color: #7B7B7B;
      padding: 0 10px 0 20px;
      // display: flex;
      .title:hover {
        .title-btn {
          display: flex;
        }
      }
      .title {
        height: 24px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        position: relative;
        .title-btn {
          display: none;
          position: absolute;
          right: 0;
          top: 0;
          background: #fff;
          .btn-a,.btn-b {
            width: 40px;
            height: 20px;
            border: 1px solid #4F17A8;
            color: #4F17A8;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2px;
          }
          .btn-b {
            background: #4F17A8;
            color: #fff;
            margin-left: 10px;
          }
        }
        .time {
          width: 50px;
          margin-right: 10px;
        }
        .date {
          margin-left: 20px;
          width: 70px;
        }
        .text {
          flex: 1;
          color: #323233;
        }
      }
    }
    :deep(.arco-collapse-item-header) {
      height: 50px;
      // background-color: #F9F9F9;
      .arco-collapse-item-header-title {
        // color: #323233;
        // font-weight: 700;
        font-size: 16px;
        font-weight: 700;
      }
    }
    :deep(.arco-collapse-item-content) {
      padding-left: 0px;
      padding-right: 0px;
      background: #fff;
      .collapse-item {
        font-size: 14px;
        color: #7B7B7B;
      }
    }
  }
  .courseware {
    // margin-top: 20px;
    padding-bottom: 20px;
    background: #fff;
    border-radius: 4px;
    .title {
      padding: 0 15px;
      font-size: 16px;
      color: #323233;
      font-weight: 700;
      .text {
        height: 50px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #E9E9E9;
      }
    }
    .courseware-list::-webkit-scrollbar {
      width: 10px;
    }
    .courseware-list::-webkit-scrollbar-thumb {
      background: #ccc content-box;
      border: 2px solid transparent;
      border-radius: 4px;
    }
    .courseware-list {
      padding: 10px 10px;
      max-height: 500px;
      overflow-y: auto;
      .arco-collapse-item {
        border-bottom: none;
      }
      :deep(.arco-collapse-item-header) {
        background-color: #F9F9F9;
        margin-bottom: 10px;
        height: 44px;
        .arco-collapse-item-header-title {
          color: #000;
          // font-weight: 700;
          font-size: 14px;
        }
      }
      :deep(.arco-collapse-item-content) {
        background: #fff;
        padding-left: 0px;
        padding-right: 0px;
        .collapse-item:hover {
          background: #f5f5f5;
          color: #4F17A8;
        }
        .collapse-item {
          height: 60px;
          margin-bottom: 6px;
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #7B7B7B;
          padding: 0 10px 0 20px;
          .collapse-item-title {
            flex: 1;
          }
          .collapse-item-time {
            font-size: 14px;
            margin-left: 30px;
          }
        }
      }
    }
  }
}
#videoPlayer,.video_box_img{
  width: 100%;
  height: 440px;
}
.titleBox{
  display: flex;
  height: 60px;
  padding: 0 10px 0 20px;
  align-items: center;
  color: #7B7B7B;
  margin-bottom: 6px;
  .titleBox-tltle {
    font-size: 14px;
  }

  .collapse-item-time{
    margin-left: 10px;
  }
  .itemBox{
    line-height: 52px;
  }
}
.titleBox:hover {
  background-color: #f5f5f5;
  color: #4F17A8 !important;
}
.titleBox-on {
  background-color: #f5f5f5;
  color: #4F17A8 !important;
}
</style>
