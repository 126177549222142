<template>
  <div class="fast-box">
    <div class="fast-label" @click="itemItemShow">
      <slot name="title"></slot>
      <div
        class="search-item-item-show f-dong"
        :class="{'search-item-item-show-zhuan': accordionData.fastShow}"
      >
        <div class="arrows" :class="{'arrows-on': accordionData.fastShow}"></div>
      </div>
    </div>
    <div class="fast-item-box" :style="`height: ${accordionData.itemItemHeight}px;`">
      <div class="fast-item-box-son" ref="fastHeight">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { reactive, ref, onMounted, watch, defineProps, toRefs } from 'vue';
  const props = defineProps({
    flag: {
      type: Boolean,
    },
  });
  // const fastHeight = ref<HTMLDataElement>(null);
    const fastHeight = ref();

  const accordionData = reactive({
    itemItemHeight: 0,
    itemItemHeightReal: 0,
    fastShow: false,
  });
  onMounted(() => {
    accordionData.itemItemHeightReal = fastHeight.value.offsetHeight;
  });
  watch(() => props.flag, (n, o) => {
    accordionData.itemItemHeightReal = fastHeight.value.offsetHeight;
    if (accordionData.fastShow) {
      accordionData.itemItemHeight = fastHeight.value.offsetHeight;
    }
  });
  const itemItemShow = () => {
    if (accordionData.itemItemHeight === 0) {
      accordionData.itemItemHeight = accordionData.itemItemHeightReal;
      accordionData.fastShow = true
    } else {
      accordionData.itemItemHeight = 0
      accordionData.fastShow = false
    }
  }
</script>
<style scoped lang="less">
.fast-box{
  border-radius: 4px;
  // border: 1px solid #CDD5DD;
  padding: 0 5px 0 15px;
  background-color: #ffffff;
}
.fast-label{
  position: relative;
  padding: 12px 30px 12px 0px;
  padding-right: 30px;
}
.search-item-item-show{
  height: 20px;
  width: 20px;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 4px;
  cursor: pointer;
}
.search-item-item-show-zhuan{
  transform: rotate(90deg);
}
.arrows{
  height: 8px;
  width: 8px;
  border-top: 1px solid #a7b0ba;
  border-left: 1px solid #a7b0ba;
  transform: rotate(135deg);
  position: absolute;
  top: 6px;
  left: 6px;
  transition: all 0.3s;
}
.arrows-on{
  // border-top: 2px solid #0081ff;
  // border-left: 2px solid #0081ff;
  border-top: 1px solid #333333;
  border-left: 1px solid #333333;
}
.fast-item-box{
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
}
.fast-item-box-son{
  border-top: 1px solid #e9e9e9;
  // padding: 10px 5px;
}
.f-dong {
  transition: all .3s linear;
}
</style>
